.inSalabamSlider .slick .postPad {
  cursor: pointer;
}
.inSalabamSlider .slick .postPad .postPad__thumbnail {
  width: auto !important;
  margin-right: 0.5rem;
  position: relative;
}
@media (min-width: 992px) {
.inSalabamSlider .slick .postPad .postPad__content {
    padding-bottom: 0;
    padding-right: 1rem;
}
}
.inSalabamSlider .slick .postPad .postPad__link button {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.inSalabamSlider .slick .postPad .postPad__title {
  margin-top: 30px;
}
.inSalabamSlider .slick-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.inSalabamSlider .slick-arrow#slickInSalabamPrev {
  left: 0;
}
.inSalabamSlider .slick-arrow#slickInSalabamNext {
  right: 0;
}
.inSalabamSlider .slick-arrow#slickInSalabamNext svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
@media (min-width: 768px) {
.inSalabamSlider .slick-arrow {
    width: 38px;
    height: 38px;
}
}