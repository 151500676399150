@media (min-width: 768px) {
.miniVp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}
}
.miniVp .miniVpText {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 100%;
}
@media (max-width: 575.98px) {
.miniVp .miniVpText {
    display: none;
}
}
.miniVp .miniVpText p {
  font-size: 12px;
  text-align: center;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0;
  width: calc(100% - 60px);
  max-width: 340px;
}
.miniVp .vp {
  overflow: hidden;
  cursor: pointer;
  height: 100%;
}
.miniVp .vp .vpInner {
  background: #f07b5d;
  color: #fff;
  font-weight: bold;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
}
.miniVp .vpCopy {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.miniVp .vpInfo {
  background: #0077CA;
  border-radius: 30px;
  min-height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  cursor: pointer;
}
.miniVp .vpInfo:hover {
  background: rgba(0, 119, 202, 0.75);
}
.miniVp .vpIcon {
  margin-right: 1rem;
}
.miniVp svg {
  margin: auto;
  width: auto;
  height: 24px;
  display: block;
}
.miniVp svg path {
  fill: #fff;
}
.miniVp .miniVpText {
  position: relative;
  min-height: 3rem;
}
.miniVp .miniVpText p {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.miniVp .vp:nth-child(1):hover ~ .miniVpText p:nth-child(1) {
  opacity: 1;
}
.miniVp .vp:nth-child(2):hover ~ .miniVpText p:nth-child(2) {
  opacity: 1;
}
.miniVp .vp:nth-child(3):hover ~ .miniVpText p:nth-child(3) {
  opacity: 1;
}
.miniVp .vp:nth-child(1):hover ~ .miniVpText p:nth-child(1),
.miniVp .vp:nth-child(1):focus ~ .miniVpText p:nth-child(1) {
  display: block;
}
.miniVp .vp:nth-child(2):hover ~ .miniVpText p:nth-child(2),
.miniVp .vp:nth-child(2):focus ~ .miniVpText p:nth-child(2) {
  display: block;
}
.miniVp .vp:nth-child(3):hover ~ .miniVpText p:nth-child(3),
.miniVp .vp:nth-child(3):focus ~ .miniVpText p:nth-child(3) {
  display: block;
}
@media (min-width: 768px) {
.miniVp .vp {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.miniVp .vp:not(:nth-child(3)) {
    padding-right: 15px;
}
}
@media (min-width: 1200px) {
.miniVp .vpCopy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0;
}
.miniVp .vpCopy span {
    display: inline-block;
    min-height: 50px;
}
.miniVp .vpIcon {
    margin-right: 0;
}
.miniVp svg {
    height: 38px;
    margin-bottom: 1rem;
}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.miniVp {
    /*
    @include hack-ie11
    {
      body
      {
        border:20px solid magenta !important;
      }
    }
    */
}
.miniVp .vp {
    min-height: 200px;
}
.miniVp .vp .vpInner {
    display: block;
}
.miniVp .vp .vpCopy {
    display: block;
}
.miniVp .vp .vpInfo {
    padding-top: 15px;
}
}