#homeContent section.section.separator hr {
  margin-top: 0;
  margin-bottom: 0;
}
#homeContent section.section:first-child {
  margin-top: 60px;
}
#homeContent section.section:not(:last-child) {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
#homeContent section.section:not(:last-child) {
    margin-bottom: 75px;
}
}