#home.inSalabam#home.hideAutocomplete #hero #search-form #search-form-autocomplete {
  display: none !important;
}
@media (min-width: 992px) {
#home.inSalabam #hero .hero__search-form {
    top: 40%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    padding-bottom: 2rem;
}
#home.inSalabam #hero .hero__slide-wrapper {
    top: 40%;
    -webkit-transform: none;
            transform: none;
}
#home.inSalabam #hero .hero__slide-title {
    font-size: 32px;
    font-weight: 700;
}
#home.inSalabam #hero .hero__slide-text {
    font-size: 18px;
    margin-top: 1rem;
    color: #fff;
}
}
#home.inSalabam #search-form {
  padding: 1.25rem;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  max-width: calc(100% - 60px);
}
@media (min-width: 992px) {
#home.inSalabam #search-form {
    max-width: 100%;
}
}
@media (min-width: 768px) and (max-width: 991.98px) {
#home.inSalabam #hero {
    height: 50vh;
}
#home.inSalabam #hero #search-form {
    max-width: 500px;
}
#home.inSalabam #hero #search-form #search-form-buttons {
    min-width: 100%;
}
}
@media (max-width: 991.98px) {
#home.inSalabam #hero #search-form.hidden {
    position: relative !important;
    z-index: 1 !important;
    visibility: visible !important;
}
#home.inSalabam #hero #search-form.hidden #searchForm {
    visibility: visible !important;
}
#home.inSalabam #hero #search-form.hidden .searchFormHidden {
    display: block !important;
}
}